//import ApiService from "@/core/services/ApiService";
import { ErrorCodes } from "../enums/StoreEnums";
import { Entitlement, GSUser } from "@/core/models/User";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
//import { AxiosRequestConfig } from "axios";

import {
  Auth,
  AuthError,
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from "firebase/auth";
import { captureException } from "@sentry/vue";
import { AppPermissions } from "@/core/models/Permissions";
import ApiService from "@/core/services/ApiService";

interface UserAuthInfo {
  user: GSUser | null;
  auth: Auth;
}

interface UserPermissionInfo {
  permission_id: AppPermissions;
  required_entitlement?: Array<Entitlement>;
  [Entitlement.PARTNER]?: Array<string>;
  [Entitlement.BUILDER]?: Array<string>;
  [Entitlement.SITE]?: Array<string>;
}

@Module({ namespaced: true, name: "auth" })
export default class AuthModule extends VuexModule implements UserAuthInfo {
  auth: Auth = getAuth();
  user: GSUser | null = null;
  permissions: Array<UserPermissionInfo> = [];

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return !!this.user;
  }

  get idToken(): () => Promise<string> | undefined {
    return () => this.auth.currentUser?.getIdToken();
  }

  get canUser(): (permission?: AppPermissions) => boolean {
    return (permission?: AppPermissions) => {
      if (!this.user) {
        return false;
      }
      return permission
        ? this.permissions.some((p) => p.permission_id === permission)
        : true;
    };
  }

  /**
   * Login
   * @param email
   * @param password
   * @returns Promise<void>
   * Logs in user through Firebase
   */
  @Action({ rawError: true })
  login({ email, password }) {
    return new Promise<void>((resolve, reject) => {
      signInWithEmailAndPassword(this.auth, email, password)
        .then((response) => {
          //console.log(response);
          //TODO: update auth claims
          resolve();
        })
        .catch((err) => {
          if (!err) {
            return reject("An error occurred.");
          }
          const error = err as AuthError;
          switch (error.code) {
            case ErrorCodes.INVALID_EMAIL:
              return reject("The email you entered is not valid.");
            case ErrorCodes.INVALID_PASSWORD:
              return reject("The information you entered is not correct.");
            case ErrorCodes.TOO_MAY_ATTEMPTS:
              return reject(
                "Your account has been temporarily locked out due to too many failed login attempts. Please reset your password or try again later."
              );
            case ErrorCodes.USER_NOT_FOUND:
              return reject(
                "This username is not associated with an active account."
              );
            case ErrorCodes.USER_DISABLED:
              return reject(
                "Your account has been disabled. Please contact an administrator for more information."
              );
            default:
              return reject(
                "Your account could not be authenticated at this time."
              );
          }
        });
    });
  }

  @Mutation
  setAuth(tokenResult) {
    //this.idToken = tokenResult.token;
    this.user = tokenResult.claims as GSUser;
  }

  @Mutation
  setPermissions(permissions: Array<UserPermissionInfo>) {
    this.permissions = permissions;
  }

  @Action
  logout() {
    this.auth.signOut(); //listener will purge local auth
  }

  @Mutation
  purgeAuth() {
    //console.log("PURGE_AUTH");
    this.user = null;
    this.permissions = [];
  }

  /**
   * Forgot Password
   * @param email
   * @returns Promise<void>
   * Send a password reset email if user is registered in Firebase
   */
  @Action({ rawError: true })
  forgotPassword({ email }) {
    return new Promise<void>((resolve) => {
      sendPasswordResetEmail(this.auth, email)
        .then(() => resolve())
        .catch(() => resolve());
    });
  }

  /**
   * Send magic link
   * @param email
   * @returns Promise<void>
   * Send a password-less email link if user is registered in Firebase
   */
  @Action({ rawError: true })
  sendMagicLink({ email }) {
    return new Promise<void>((resolve, reject) => {
      sendSignInLinkToEmail(this.auth, email, {
        url: import.meta.env.VITE_MAGIC_LINK_URL,
        handleCodeInApp: true,
      })
        .then(() => resolve())
        .catch(() => {
          return reject("The link could not be generated at this time.");
        });
    });
  }

  /**
   * Verify magic link
   * @param email
   * @returns Promise<void>
   * Send a password-less email link if user is registered in Firebase
   */
  @Action({ rawError: true })
  verifyMagicLink({ email }) {
    return new Promise<void>((resolve, reject) => {
      signInWithEmailLink(this.auth, email)
        .then(() => resolve())
        .catch(() => {
          return reject("The link could not be generated at this time.");
        });
    });
  }

  /**
   * Verify password reset code
   * @param actionCode
   * @returns Promise<string>
   * Verify the password reset code is valid and return the user email if valid
   */
  @Action({ rawError: true })
  verifyPasswordResetCode({ actionCode }) {
    return new Promise<string>((resolve, reject) => {
      verifyPasswordResetCode(this.auth, actionCode)
        .then((email) => resolve(email))
        .catch(() => {
          return reject(
            "The password action code is invalid or has expired. Please try again."
          );
        });
    });
  }

  /**
   * Confirm password reset
   * @param actionCode
   * @param newPassword
   * @returns Promise<>
   * Verify the password reset code is valid and return the user email if valid
   */
  @Action({ rawError: true })
  confirmPasswordReset({ actionCode, password }) {
    return new Promise<void>((resolve, reject) => {
      confirmPasswordReset(this.auth, actionCode, password)
        .then(() => resolve())
        .catch(() => {
          return reject(
            "The password could not be reset at this time. Please try again."
          );
        });
    });
  }

  @Action({ rawError: true })
  verifyAuth() {
    return new Promise((resolve) => {
      this.auth.onAuthStateChanged(async (user) => {
        if (user) {
          const token = await user.getIdTokenResult();
          this.context.commit("setAuth", token);
          const permissions = await ApiService.get(
            "/auth/user-permissions.php"
          );
          this.context.commit("setPermissions", permissions.data);
        } else {
          this.context.commit("purgeAuth");
        }
        resolve(user);
      });
    });
  }
}
