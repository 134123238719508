<template>
  <div>
    <router-view />
    <DynamicDialog></DynamicDialog>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, nextTick } from "vue";
import { initializeComponents } from "@/core/plugins/keenthemes";

export default defineComponent({
  name: "app",
  setup() {
    onMounted(() => {
      nextTick(() => {
        initializeComponents();
      });
    });
  },
});
</script>
<style lang="scss">
//@import "bootstrap/dist/css/bootstrap.min.css";
@import "bootstrap-icons/font/bootstrap-icons.css";
@import "apexcharts/dist/apexcharts.css";
@import "sweetalert2/dist/sweetalert2.min.css";
@import "@fortawesome/fontawesome-free/css/all.min.css";
@import "line-awesome/dist/line-awesome/css/line-awesome.css";

// Main demo style scss
@import "assets/sass/plugins";
@import "assets/sass/style";

//unused styles
//@import "~quill/dist/quill.snow.css";
//@import "~nouislider/distribute/nouislider.css";
//@import "~socicon/css/socicon.css";
//@import "~animate.css";
//@import "~@yaireo/tagify/src/tagify.scss";
//@import "dropzone/dist/dropzone.css";
//@import "~@vueform/multiselect/themes/default.css";
//@import "~prism-themes/themes/prism-shades-of-purple.css";
//@import "element-plus/theme-chalk/index.css";

// Dark mode demo style scss
//@import "assets/sass/plugins.dark";
//@import "assets/sass/style.dark";

//RTL version styles
//@import "assets/css/style.rtl.css";
</style>
<style>
@import "style.css";
</style>
