<template>
  <!--begin::Alert-->
  <div
    class="alert d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10"
    :class="`${bgClasses} ${dismissible ? 'alert_dismissible' : ''}`"
  >
    <!--begin::Close-->
    <button
      v-if="dismissible"
      type="button"
      class="position-absolute top-0 end-0 m-2 btn btn-icon"
      :class="`btn-icon-${color}`"
      data-bs-dismiss="alert"
    >
      <span class="svg-icon svg-icon-1">
        <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
      </span>
    </button>
    <!--end::Close-->
    <span class="svg-icon svg-icon-5tx mb-5" :class="`svg-icon-${color}`">
      <inline-svg :src="icon" />
    </span>
    <!--begin::Content-->
    <div class="text-center text-dark">
      <h1 class="fw-bolder mb-5">{{ title }}</h1>
      <div
        class="separator separator-dashed opacity-25 mb-5"
        :class="`border-${color}`"
      ></div>
      <div class="mb-9" v-html="body"></div>
      <!--begin::Buttons-->
      <div class="d-flex flex-center flex-wrap" v-if="button">
        <a
          :href="buttonLink"
          v-if="buttonLink.startsWith('http')"
          :class="`btn btn-${color}`"
        >
          {{ buttonLabel }}
        </a>
        <router-link v-else :to="buttonLink" :class="`btn btn-${color}`">
          {{ buttonLabel }}
        </router-link>
      </div>
      <div class="d-flex flex-center flex-wrap" v-else-if="offerReload">
        <button @click="reloadPage()" :class="`btn btn-${color}`">
          Try Again
        </button>
      </div>
      <!--end::Buttons-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Notice-->
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "kt-alert",
  props: {
    bgClasses: { type: String, default: "bg-light-danger" },
    icon: { type: String, default: "/media/icons/duotune/general/gen044.svg" },
    title: { type: String },
    body: { type: String },
    dismissible: { type: Boolean, default: false },
    button: { type: Boolean, default: false },
    buttonLabel: { type: String, default: "Button" },
    buttonLink: { type: String, default: "/" },
    offerReload: { type: Boolean, default: false },
    color: { type: String, default: "danger" },
  },
  components: {},
  methods: {
    reloadPage() {
      window.location.reload();
    },
  },
});
</script>
