import { createRouter, createWebHistory } from "vue-router";
import { setupLayouts } from "virtual:generated-layouts";
import generatedRoutes from "virtual:generated-pages";
import { authStore } from "@/store/modules";
import { AppPermissions } from "@/core/models/Permissions";

const routes = setupLayouts(generatedRoutes);
//console.log(routes);

declare module "vue-router" {
  interface RouteMeta {
    permission?: AppPermissions;
    auth: boolean;
    title: string;
    siteHeader?: boolean;
    breadcrumbs?: Array<Breadcrumb>;
  }
  interface Breadcrumb {
    title: string;
    path: string;
  }
}

const router = createRouter({
  history: createWebHistory(""),
  routes,
});

router.beforeEach(async (to) => {
  // Scroll page to top on every route change
  if (to.meta.auth && !authStore.isUserAuthenticated) {
    return {
      name: "login",
      query: { redirect: to.fullPath },
    };
  } else if (to.meta.auth && !authStore.canUser(to.meta.permission)) {
    return {
      name: "404",
    };
  } else {
    document.title = `Gas Services - ${to.meta.title}`;
  }

  /*
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);*/
});

export default router;
