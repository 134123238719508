<template>
  <div class="card">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">
          {{title}}
        </span>
        <span class="text-muted mt-3 fw-bold fs-7">
          {{subtitle}}
        </span>
      </h3>
      <div class="card-toolbar" data-kt-buttons="true">
        <a
          class=" 
            btn btn-color-muted btn-active btn-active-primary
            px-4
            me-1"
          :class="{ 
              active: show === 'month',
              'text-hover-primary': show != 'month'
              }"
          data-toggle="tab"
          @click="show = 'month'"
        >
          Month
        </a>
        <a
          class=" 
            btn btn-color-muted btn-active btn-active-primary
            px-4
            me-1"
          data-toggle="tab"
          :class="{ 
              active: show === 'week',
              'text-hover-primary': show != 'week'
              }"
          @click="show = 'week'"
        >
          Week
        </a>
        <a
          class=" 
            btn btn-color-muted btn-active btn-active-primary
            px-4
            me-1"
          data-toggle="tab"
          :class="{ 
              active: show === 'day',
              'text-hover-primary': show != 'day'
              }"
          @click="show = 'day'"
        >
          Day
        </a>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <!--begin::Chart-->
      <apexchart
        class="card-rounded-bottom"
        type="area"
        :options="chartOptions"
        :series="series"
      ></apexchart>
      <!--end::Chart-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import type { DeepReadonly } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import type { TimelineData } from "@/core/models/Dashboard";

interface Props {
    title: string,
    subtitle?: string,
    data: DeepReadonly<TimelineData>
}
const props = withDefaults(defineProps<Props>(), {subtitle: ''});

const labelColor = getCSSVariableValue("--bs-gray-500");
const borderColor = getCSSVariableValue("--bs-gray-200");

const baseColor = getCSSVariableValue("--bs-success");
const baseLightColor = getCSSVariableValue("--bs-light-success");

const chartOptions = {
    chart: {
      fontFamily: "inherit",
      type: "area",
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
    },
    plotOptions: {},
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: "solid",
      opacity: 1
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [baseColor]
    },
    xaxis: {
      type: 'datetime',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: true,
        style: {
          colors: labelColor,
          fontSize: "12px"
        }
      },
      crosshairs: {
        position: "front",
        stroke: {
          color: borderColor,
          width: 2,
          dashArray: 4
        }
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px"
        }
      }
    },
    yaxis: {
      show: true,
      labels: {
        show: true,
        style: {
          colors: labelColor,
          fontSize: "12px"
        }
      }
    },
    tooltip: {
      style: {
        fontSize: "12px"
      },
      x: {
        format: 'ddd, MMM d, yyyy'
      },
      y: {
        formatter: function(val) {
          return val + " lots completed";
        }
      }
    },
    colors: [baseLightColor],
    markers: {
      colors: [baseLightColor],
      strokeColor: [baseColor],
      strokeWidth: 3
    },
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
          lines: {
              show: true
          }
      },
    }
  };
const show = ref('week');
const series = computed(() => {
  switch(show.value) {
    case 'week':
      return [{ name: 'Weekly', data: props.data.week }];
    case 'month':
      return [{ name: 'Monthly', data: props.data.month }];
    default:
      return [{ name: 'Daily', data: props.data.day }];
  }
});
</script>
