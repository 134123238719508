<template>
  <section>
    <DashboardAdmin v-if="canUser(AppPermissions.DASHBOARD_ADMIN)"></DashboardAdmin>
    <DashboardClient v-else-if="canUser(AppPermissions.SITES_VIEW)"></DashboardClient>
    <Alert
      v-else-if="canUser(AppPermissions.TECH_APP_ACCESS)"
      bgClasses="bg-light-primary"
      color="primary"
      title="Coming Soon"
      body="The dashboard is currently under development."
      button
      buttonLink="https://tech.gas-services.ca"
      buttonLabel="Open Commissioning App"
    ></Alert>
    <Notice
      v-else
      title="Coming Soon"
      body="The dashboard is currently under development."
    ></Notice>
  </section>
</template>
<script setup lang="ts">
import DashboardAdmin from "@/components/DashboardAdmin.vue";
import DashboardClient from "@/components/DashboardClient.vue";
import useUserPermissions from "@/core/composables/useUserPermissions";
import Notice from "@/components/ui/Notice.vue";
import Alert from "@/components/ui/Alert.vue";
import { AppPermissions } from "@/core/models/Permissions";

const { canUser } = useUserPermissions();
</script>
<route>
{
    name: 'dashboard',
    meta: {
        title: 'Dashboard',
        auth: true,
    }
}
</route>
