enum Actions {
    // action types
    ADD_BODY_CLASSNAME = "addBodyClassName",
    REMOVE_BODY_CLASSNAME = "removeBodyClassName",
    ADD_BODY_ATTRIBUTE = "addBodyAttribute",
    REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
    ADD_CLASSNAME = "addClassName",
    VERIFY_AUTH = "verifyAuth",
    LOGIN = "login",
    LOGOUT = "logout",
    REGISTER = "register",
    UPDATE_USER = "updateUser",
    FORGOT_PASSWORD = "forgotPassword",
    SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  }
  
  enum Mutations {
    // mutation types
    SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
    PURGE_AUTH = "logOut",
    SET_AUTH = "setAuth",
    SET_USER = "setUser",
    SET_PASSWORD = "setPassword",
    SET_ERROR = "setError",
    SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
    SET_LAYOUT_CONFIG = "setLayoutConfig",
    RESET_LAYOUT_CONFIG = "resetLayoutConfig",
    OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
    OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  }
  
  enum ErrorCodes {
    INVALID_PASSWORD = "auth/wrong-password",
    INVALID_EMAIL = "auth/invalid-email",
    TOO_MAY_ATTEMPTS = "auth/too-many-requests",
    USER_NOT_FOUND = "auth/user-not-found",
    EMAIL_IN_USE = "auth/email-already-in-use",
    WEAK_PASSWORD = "auth/weak-password",
    USER_DISABLED = "auth/user-disabled",
    UNKNOWN_ERROR = "unknown"
  }

  export { Actions, Mutations, ErrorCodes };
  