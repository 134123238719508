import { App } from "vue";
import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import Dialog from "primevue/dialog";
import DialogService from "primevue/dialogservice";
import DynamicDialog from "primevue/dynamicdialog";

import 'primevue/resources/themes/lara-light-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

/**
 * Initialize PrimeVue library
 * @param app vue instance
 */
export function initPrimeVue(app: App<Element>) {
  app.use(PrimeVue, {
    pt: {
      multiSelect: {
        list: { class: "ps-0 mb-0" },
      },
      dropdown: {
        list: { class: "ps-0 mb-0" },
      },
      menubar: {
        menu: { class: 'ps-0 mb-0' }
      }
    },
  });
  app.component('DataTable', DataTable);
  app.component('Column', Column);
  app.component("Dropdown", Dropdown);
  app.component("MultiSelect", MultiSelect);
  app.component("Dialog", Dialog);
  app.component("DynamicDialog", DynamicDialog);
  app.use(DialogService);
}