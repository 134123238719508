import { App } from "vue";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { Router } from "vue-router";

/**
 * Initialize Sentry logging component
 * @param app vue instance
 */
export function initSentry(app: App<Element>, router: Router) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    integrations: [
        new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "gas-services.ca", /^\//],
        }),
    ],
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        if(event.exception.values && event.exception.values[0].value?.includes("Failed to fetch dynamically imported module")) {
          window.location.reload();
          return null;
        }
        Sentry.showReportDialog({ eventId: event.event_id });
      }
      return event;
    },
    //For development, log errors in console
    logErrors: true,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    });
}