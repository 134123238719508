import { Mutation, Module, VuexModule } from "vuex-module-decorators";

interface Breadcrumb {
  title: string;
  path: string;
}

interface StoreInfo {
  pageTitle: string;
  breadcrumbs: Array<Breadcrumb>;
}

@Module({ namespaced: true, name: "breadcrumbs" })
export default class BreadcrumbsModule extends VuexModule implements StoreInfo {
  breadcrumbs = [] as Array<Breadcrumb>;
  pageTitle = "";

  /**
   * Get breadcrumb array for current page
   * @returns object
   */
  get getBreadcrumbs(): Array<Breadcrumb> {
    return this.breadcrumbs;
  }

  /**
   * Get current page title
   * @returns string
   */
  get getPageTitle(): string {
    return this.pageTitle;
  }

  /**
   * Set current page breadcrumbs
   * @param {string} pageTitle Current page title
   * @param {Array<Breadcrumb>} breadcrumbs Current page breadcrumbs
   */
  @Mutation
  setCurrentPageBreadcrumbs({
    pageTitle = "",
    breadcrumbs = [] as Array<Breadcrumb>,
  }) {
    this.pageTitle = pageTitle;
    this.breadcrumbs = breadcrumbs;
  }

  /**
   * Set current page title only, keeping breadcrumbs the same
   * @param {string} pageTitle Current page title
   */
  @Mutation
  updateCurrentPageTitle(pageTitle = "") {
    this.pageTitle = pageTitle;
  }
}
