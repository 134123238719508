import { createApp } from "vue";
import "@/core/plugins/firebase";
//import "bootstrap";
import App from "./App.vue";

import router from "./router";
import store from "./store";
import { authStore } from "./store/modules";

import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import { initPrimeVue } from "@/core/plugins/prime-vue";
import { initSentry } from "@/core/plugins/sentry";
import { initGoogleMaps } from "@/core/plugins/google-maps";
import { createPinia } from "pinia";

const app = createApp(App);
app.use(store);
const pinia = createPinia();
app.use(pinia);

ApiService.init(app);
initSentry(app, router);
initApexCharts(app);
initInlineSvg(app);
initPrimeVue(app);
initVeeValidate();
initGoogleMaps(app);
/* catch preload event */
window.addEventListener("vite:preloadError", (event) => {
  //TODO: not being triggered, may require update to Vite
  window.location.reload();
  event.preventDefault();
});

authStore.verifyAuth().then(() => {
  app.use(router);
  app.mount("#app");
});
