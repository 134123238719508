<template>
    <div class="row gy-4">
        <div class="col-lg-4">
            <WidgetActivityList 
                title="Latest Activity" 
                subtitle="Latest lots commissioned"
                :data="activityList"
                :action-links="activityListLinks"
            ></WidgetActivityList>
        </div>
        <div class="col-lg-8">
            <WidgetTimelineGraph
                title="Commissioning Stats"
                subtitle="Review work trends and past performance."
                :data="timelineData"
            ></WidgetTimelineGraph>
        </div>
    </div>
</template>
<script setup lang="ts">
//components
import WidgetTimelineGraph from "@/components/WidgetTimelineGraph.vue";
import WidgetActivityList from "@/components/WidgetActivityList.vue";

//composables
import useDashboardActivityList from "@/core/composables/dashboardActivityList";
import useDashboardTimelineGraph from "@/core/composables/dashboardTimelineGraph";


//Timeline graph
const { timelineData } = useDashboardTimelineGraph();

//Activity list
const { activityList, activityListLinks } = useDashboardActivityList();

</script>