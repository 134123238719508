import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { authStore } from "@/store/modules";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import { APIError } from "@/core/models/CoreEnums";
//import { captureException } from "@sentry/vue";

interface IApiError {
  type: APIError;
  message: string;
  raw: Error;
}
/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);

    ApiService.vueInstance.axios.defaults.baseURL =
      import.meta.env.VITE_API_URL;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
    ApiService.vueInstance.axios.interceptors.request.use(async (request) => {
      if (authStore.isUserAuthenticated && request?.headers != null) {
        const token = await authStore.idToken();
        if (token) request.headers.common["Authorization"] = `Bearer ${token}`;
      }
      return request;
    });
    ApiService.vueInstance.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        //clean up error response before passing it back to the caller

        const errorResponse = {
          type: error.response?.status,
          message: error.response?.data?.message,
          raw: error,
        } as IApiError;
        if (
          !errorResponse.type ||
          !Object.values(APIError).includes(errorResponse.type)
        ) {
          errorResponse.type = APIError.SERVER_ERROR;
        }
        if (errorResponse.type == APIError.UNAUTHORIZED) {
          //try the call again first
        } else if (
          errorResponse.type == APIError.SERVER_ERROR &&
          !errorResponse.message
        ) {
          //use generic error message
          errorResponse.message =
            "Your request could not be completed. Please try again later.";
        }
        //TOOD: review custom default messages for various return types
        return Promise.reject(errorResponse);
      }
    );
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    var resourceWithParams = resource;
    if (params.params) {
      resourceWithParams += "?" + new URLSearchParams(params.params);
    }
    return ApiService.vueInstance.axios.get(resourceWithParams, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    if (!!slug) resource += `/${slug}`;
    return ApiService.vueInstance.axios.get(resource);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param data: object
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    data: object,
    params: AxiosRequestConfig = {}
  ): Promise<AxiosResponse> {
    let searchParams = new FormData();

    if (data instanceof FormData) {
      searchParams = data;
    } else {
      for (var key in data) {
        let val = data[key];
        /*if (typeof val == "object") {
          val = JSON.stringify(val);
        }*/
        searchParams.append(key, val);
      }
    }
    return ApiService.vueInstance.axios.post(resource, searchParams, params);
  }
}

export default ApiService;
