export enum AccessLevel {
  ADMIN = "admin",
  CLIENT = "client",
  PUBLIC = "public",
  TECHNICIAN = "technician",
}

export enum ApplianceType {
  FURNACE = 1,
  BOILER = 2,
  FIREPLACE = 3,
  RANGE = 4,
  ERVHRV = 5,
  AC = 6,
}

export enum GenericStatus {
  PENDING = "pending",
  COMPLETED = "completed",
  FAILED = "failed",
}

export enum APIError {
  NO_RECORDS = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  SERVER_ERROR = 500,
}

export enum AIVFormType {
  SECTION_A = 1,
  SECTION_A_B = 2,
}

export enum UserStatus {
  INVITED = "0",
  ACTIVE = "1",
  INACTIVE = "2",
}
