import { App } from "vue";
import VueGoogleMaps from "@fawmi/vue-google-maps"

/**
 * Initialize Google Maps library
 * @param app vue instance
 */
export function initGoogleMaps(app: App<Element>) {
  app.use(VueGoogleMaps, {
    load: {
        v: 'weekly',
        key: import.meta.env.VITE_GOOGLE_MAPS_API_KEY
    }
  });
}
