import ApiService from "@/core/services/ApiService";
import { reactive, onMounted, readonly } from "vue";
import { TimelineData } from "@/core/models/Dashboard";

export default function useDashboardTimelineGraph() {
    const timelineData = reactive<TimelineData>({
        loaded: false,
        month: [],
        week: [],
        day: []
    });
    const loadTimelineData = async function() {
        try {
            const dailyData = ApiService.query("dashboard/stats-completed-timeline.php", {params: {num_periods: 14, period_length: 'daily'}});
            const weeklyData = ApiService.query("dashboard/stats-completed-timeline.php", {params: {num_periods: 20, period_length: 'weekly'}});
            const monthlyData = ApiService.query("dashboard/stats-completed-timeline.php", {params: {num_periods: 12, period_length: 'monthly'}});
            const results = await Promise.all([dailyData, weeklyData, monthlyData]);
            
            timelineData.day = results[0].data;
            timelineData.week = results[1].data;
            timelineData.month = results[2].data;
            timelineData.loaded = true;
        } catch(err) {
            console.error(err);
        }
    };
    onMounted(() => {
        loadTimelineData();
    });

    return {
        timelineData: readonly(timelineData),
        loadTimelineData
    }
}