import ApiService from "@/core/services/ApiService";
import { reactive, onMounted, readonly } from "vue";
import { ActivityListData } from "@/core/models/Dashboard";
import { DropdownOptions } from "@/core/models/DropdownOptions";

export default function useDashboardActivityList() {
  const activityList = reactive<ActivityListData>([]);

  const activityListLinks: DropdownOptions = [
    {
      text: "Furnace Commissioning Entries",
      link: "https://gas-services.ca/wp-admin/admin.php?page=gf_entries&id=1",
      newTab: true,
    },
    {
      text: "Boiler Commissioning Entries",
      link: "https://gas-services.ca/wp-admin/admin.php?page=gf_entries&id=6",
      newTab: true,
    },
    {
      text: "Fireplace Commissioning Entries",
      link: "https://gas-services.ca/wp-admin/admin.php?page=gf_entries&id=13",
      newTab: true,
    },
    {
      text: "Gas Range Commissioning Entries",
      link: "https://gas-services.ca/wp-admin/admin.php?page=gf_entries&id=18",
      newTab: true,
    },
    {
      text: "ERV/HRV Commissioning Entries",
      link: "https://gas-services.ca/wp-admin/admin.php?page=gf_entries&id=24",
      newTab: true,
    },
  ];

  const loadActivityList = async () => {
    try {
      const response = await ApiService.query("dashboard/activity-list.php", {
        params: { limit: 10 },
      });
      if (response.status == 204) {
        //no results
      } else if (response && response.data) {
        const data = response.data;
        activityList.length = 0;
        var last_date = null;
        for (const one of data) {
          const badgeClass = one.technician.toLowerCase().includes("fortier")
            ? "text-success"
            : "text-warning";
          if (last_date != one.date) {
            activityList.push({
              time: "",
              desc: "Commissioned on: " + one.date,
              badge: "fa fa-genderless text-info",
              bold: true,
            });
            last_date = one.date;
          }
          activityList.push({
            time: one.time,
            desc: `<a href="${one.entry_url}" target="_blank">${one.site} lot ${one.lot}</a> (${one.technician})`,
            badge: "fa fa-genderless " + badgeClass,
          });
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  onMounted(loadActivityList);

  return {
    activityList: readonly(activityList),
    activityListLinks,
    loadActivityList,
  };
}
