<template>
    <div>
        <Loader v-if="loading"></Loader>
        <Notice v-else-if="!site_list" title="No active sites" body="No active sites are associated with your account. Please contact us to reactivate or create a new site."></Notice>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import ApiService from '@/core/services/ApiService';
import Loader from './ui/Loader.vue';
import Notice from './ui/Notice.vue';
import { useRouter } from 'vue-router';

const site_list = ref();
const loading = ref(true);
const router = useRouter();

ApiService.get("sites/client-site.php")
    .then(result => {
        const siteId = result.data.site_id;
        if(siteId.length == 1) {
            router.push(`/sites/${siteId}`);
        } else {
            router.push('/sites');
        }
        site_list.value = siteId;
        loading.value = false;
    })
    .catch(() => {
        loading.value = false;
    })

</script>

<style scoped>

</style>